var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.prList,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.$store.state.user.roleID == 5)?_c('v-tabs',{attrs:{"color":"header"},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-tab',[_vm._v(" Verified ")]),_c('v-tab',[_vm._v(" Pending ")]),_c('v-tab',[_vm._v(" For Canvass ")]),_c('v-tab',[_vm._v(" For Recanvass ")])],1):_vm._e(),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","placeholder":"Search for PR Code/Purpose","color":"green darken-2","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mt-2",attrs:{"color":"green","item-text":"OfficeDescription","item-value":"OfficeID","items":_vm.office,"dense":"","hide-details":"","label":"Filter by office"},on:{"change":function($event){return _vm.initialize()}},model:{value:(_vm.OfficeID),callback:function ($$v) {_vm.OfficeID=$$v},expression:"OfficeID"}})],1)]},proxy:true},{key:"item.PRCODE",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isApproved == 1 ? item.PRCODE : item.PRCODETemp)+" ")]}},{key:"item.BudgetCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.isParent == 1 ? 'Consolidated' : item.BudgetCode)+" ")]}},{key:"item.GrandTotal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.GrandTotal)))])]}},{key:"item.other_actions",fn:function(ref){
var item = ref.item;
return [(item.PSUStatusID != 6)?_c('span',{staticClass:"text-no-wrap"},[(_vm.$store.state.user.roleID == 5 && !item.isDBM)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"blue darken-2","dark":""},on:{"click":function($event){return _vm.rfq(item)}}},[_vm._v(" RFQ ")]):_vm._e(),(_vm.$store.state.user.BACaccess == 1 && item.isParent == 0 && item.PSUStatusID == 7 && item.IsCanvassed == 1)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"orange darken-2","dark":""},on:{"click":function($event){return _vm.aoq(item)}}},[_vm._v(" AOQ ")]):_vm._e(),(_vm.$store.state.user.roleID == 5 && item.isDBM)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"red darken-2","dark":""},on:{"click":function($event){return _vm.apr(item)}}},[_vm._v(" APR ")]):_vm._e(),(_vm.$store.state.user.roleID == 5 && item.PSUStatusID == 7 && item.IsCanvassed == 1)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"green darken-2","dark":""},on:{"click":function($event){return _vm.noa(item)}}},[_vm._v(" NOA ")]):_vm._e(),(_vm.$store.state.user.roleID == 5 && item.PSUStatusID == 7 && item.IsCanvassed == 1)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"green darken-2","dark":""},on:{"click":function($event){return _vm.po(item)}}},[_vm._v(" PO ")]):_vm._e(),(_vm.$store.state.user.roleID == 6 || _vm.$store.state.user.roleID == 15)?_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"amber accent-4","dark":""},on:{"click":function($event){return _vm.iar(item)}}},[_vm._v(" IAR ")]):_vm._e()],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"x-small":"","outlined":"","color":"blue darken-2","dark":""},on:{"click":function($event){return _vm.viewPR(item)}}},[_vm._v(" VIEW ")])]}}],null,true)}),_c('prModal',{attrs:{"prData":_vm.prItem}}),_c('poModal',{attrs:{"prData":_vm.poItem}}),_c('noaModal',{attrs:{"prData":_vm.noaItem}}),_c('iarModal',{attrs:{"prData":_vm.iarItem}}),_c('canvasModal',{attrs:{"canvasData":_vm.prItem1}}),_c('rfqModal',{attrs:{"rfqData":_vm.rfqItem}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }