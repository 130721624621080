var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.NTPLists,"options":_vm.options,"server-items-length":_vm.totalCount,"loading":_vm.loading,"footer-props":_vm.footerProps},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"mt-2",attrs:{"append-icon":"mdi-magnify","label":"Search","color":"green darken-2","single-line":"","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('v-spacer')],1)]},proxy:true},{key:"item.NTPCode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.NTPCode)+" ")]}},{key:"item.NOAPurpose",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.NOAPurpose)+" ")]}},{key:"item.gtotal",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v("₱ "+_vm._s(_vm.formatPrice(item.gtotal)))])]}},{key:"item.Actions",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_c('v-btn',{staticClass:"mr-1",attrs:{"color":"blue darken-2","dense":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.viewNTP(item)}}},[_vm._v(" View ")]),_c('v-btn',{staticClass:"mr-1 white--text",attrs:{"color":"red darken-3","dense":"","outlined":"","x-small":""},on:{"click":function($event){return _vm.NTP(item)}}},[_vm._v(" Print ")])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.ntpDialog),callback:function ($$v) {_vm.ntpDialog=$$v},expression:"ntpDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Generate NTP")]),_c('div',{staticClass:"mx-6 pt-3"},[_c('v-menu',{ref:"menu1",staticClass:"mb-10",attrs:{"dense":"","close-on-content-click":false,"return-value":_vm.NTPDate,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.NTPDate=$event},"update:return-value":function($event){_vm.NTPDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"dense":"","label":"NTP Date","readonly":"","color":"header"},model:{value:(_vm.NTPDate),callback:function ($$v) {_vm.NTPDate=$$v},expression:"NTPDate"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","color":"header"},model:{value:(_vm.NTPDate),callback:function ($$v) {_vm.NTPDate=$$v},expression:"NTPDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"header"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"header"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.NTPDate)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-card-actions',{staticClass:"mt-5"},[_c('strong',[_c('i',[_vm._v(_vm._s(_vm.selectedData.NOACode))])]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-2","outlined":""},on:{"click":function($event){_vm.ntpDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"header white--text"},on:{"click":function($event){return _vm.confirmNTP()}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-printer")]),_vm._v("GENERATE")],1)],1)],1)],1),_c('ntpModal',{attrs:{"poData":_vm.ntpData}}),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }