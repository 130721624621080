<template>
<div>
  <v-data-table
    :headers="headers"
    :items="NTPLists"
    :options.sync="options"
    :server-items-length="totalCount"
    :loading="loading"
    :footer-props="footerProps"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-text-field
            v-model="searchText"
            append-icon="mdi-magnify"
            label="Search"
            color="green darken-2"
            class="mt-2"
            single-line
            hide-details
          ></v-text-field>
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
      <template v-slot:[`item.NTPCode`]="{ item }">
        {{ item.NTPCode }}
      </template>
      <template v-slot:[`item.NOAPurpose`]="{ item }">
        {{ item.NOAPurpose }}
      </template>
      <template v-slot:[`item.gtotal`]="{ item }">
        <span class="text-no-wrap">₱ {{ formatPrice(item.gtotal) }}</span>
      </template>
      <template v-slot:[`item.Actions`]="{ item }">
        <span class="text-no-wrap">
          <v-btn
            @click="viewNTP(item)"
            color="blue darken-2"
            class="mr-1"
            dense
            outlined
            x-small
          >
            View
          </v-btn>
          <v-btn
            @click="NTP(item)"
            color="red darken-3"
            class="mr-1 white--text"
            dense
            outlined
            x-small
          >
            Print
          </v-btn>
        </span>
      </template>
  </v-data-table>
  <v-dialog v-model="ntpDialog" max-width="500px">
          <v-card>
            <v-card-title class="headline">Generate NTP</v-card-title>
            <div class="mx-6 pt-3">
              <v-menu
                dense
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                :return-value.sync="NTPDate"
                transition="scale-transition"
                offset-y
                class="mb-10"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    dense
                    v-model="NTPDate"
                    label="NTP Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    color="header"
                  ></v-combobox>
                </template>
                <v-date-picker
                  v-model="NTPDate"
                  no-title
                  scrollable
                  color="header"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="header" @click="menu1 = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="header"
                    @click="$refs.menu1.save(NTPDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
            <v-card-actions class="mt-5">
              <strong><i>{{selectedData.NOACode}}</i></strong>
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="ntpDialog = false">Cancel</v-btn>
              <v-btn color="header white--text" @click="confirmNTP()"><v-icon class="mr-1">mdi-printer</v-icon>GENERATE</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      <ntpModal :poData="ntpData" />
      <fade-away-message-component
			displayType="variation2"
			v-model="fadeAwayMessage.show" 
			:message="fadeAwayMessage.message" 
			:header="fadeAwayMessage.header" 
			:top="fadeAwayMessage.top"
			:type="fadeAwayMessage.type"
		></fade-away-message-component>
  </div>
</template>

<script>
  export default {
    components: {
    ntpModal: () => import("../PO-Components/PSUViewNTPItem"),
    },
    props: {
    fiscalYear: null,
    },
    data: () => ({
      searchText: '',
      amountErr: null,
      editItemId: null,
      footerProps: {'items-per-page-options': [5, 10, 20, 50, 100, 500]},
      totalCount: 0,
      loading: true,
       options: {},
      fadeAwayMessage : {
			show : false,
			type : 'success',
			header : 'Successfully Saved!',
			message : '',
			top : 10
		},
      isLoading: false,
      menu1: false,
      menu2: false,
      formData: {
        ItemName: null,
      },
      Dialog: false,
      NTPLists: [],
      headers: [
        // {
        //   text: 'Canvass No.',
        //   align: 'start',
        //   sortable: false,
        //   value: 'CanvassNo',
        //   class: "green darken-2 white--text text-caption font-weight-bold"
        // },
        {
          text: 'NTP Code',
          align: 'start',
          sortable: false,
          value: 'NTPCode',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'PO Code',
          align: 'start',
          sortable: false,
          value: 'POCode',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Purpose',
          align: 'start',
          sortable: false,
          value: 'NOAPurpose',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Supplier',
          align: 'start',
          sortable: false,
          value: 'CompanyName',
          class: "green darken-2 white--text text-caption font-weight-bold"
        },
        {
          text: 'Grand Total',
          align: 'right',
          sortable: false,
          value: 'gtotal',
          class: "green darken-2 white--text text-caption font-weight-bold" 
        },
        { text: 'Actions', value: 'Actions', align: "center", sortable: false, class: "green darken-2 white--text text-caption font-weight-bold" },
      ],
      ntpData: [],
      NTPDate: null,
      ntpDialog: false,
      selectedData: {}
    }),
    watch: {
      options: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      searchText: {
        handler () {
         this.initialize();
        },
        deep: true,
      },
      fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
    },
    mounted(){
      this.getLIBCategories();
      this.eventHub.$on("closeNTPItemModal", () => {
      this.initialize();
    });
    },
    beforeDestroy() {
    this.eventHub.$off("closeNTPItemModal");
    },
    methods: {
      cancel() {
        this.editItemId = null;
        this.$refs.formRef.reset();
        this.Dialog = false;
      },
      viewNTP(item) {
      this.ntpData = item;
    },
      initialize () {
        if(this.fiscalYear != null){
        this.loading = true
         let data = new FormData();
         if(this.options.page){
              data.append('page', this.options.page);
              data.append('itemsPerPage', this.options.itemsPerPage);
         }else{
              data.append('page', 1);
              data.append('itemsPerPage', 10);
         }
         data.append("searchText", this.searchText);
         data.append("FiscalYearID", this.fiscalYear);
              this.axiosCall(
              '/getNTP',
              'POST',
              data
          )
          .then((res) => {
            this.loading = false
              this.NTPLists = res.data.data.result;
              this.totalCount = res.data.data.total;
              if(this.totalCount <= 10){
                this.options.page = 1;
              }
          })
      }
      },
    editItem(data) {
      this.editItemId = data.ItemID;
      this.formData.ItemName = data.ItemName;
      this.Dialog = true;
		},
      save () {
         if(this.$refs.formRef.validate() && !this.amountErr){
              this.isLoading = true;
             let data = new FormData();
              data.append('ItemName', this.formData.ItemName);
              data.append('CategoryID', this.formData.CategoryID);
                         this.axiosCall(
                        '/LIBItems/add',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Added!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
      update () {
         if(this.$refs.formRef.validate()){
              this.isLoading = true;
             let data = new FormData();
              data.append('ItemID', this.editItemId);
               data.append('ItemName', this.formData.ItemName);
               data.append('CategoryID', this.formData.CategoryID);
                         this.axiosCall(
                        '/LIBItems/edit',
                        'POST',
                        data
                    )
                    .then((res) => {
                        this.isLoading = false;
                        if(res.data.status) {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'success';
                        this.fadeAwayMessage.message = 'Successfully Updated!';
                        this.fadeAwayMessage.header = 'System Message';
                        this.$refs.formRef.reset();
                        this.Dialog = false;
                        this.editItemId = null;
                        
                         this.initialize();
                        }else {
                        this.fadeAwayMessage.show = true;
                        this.fadeAwayMessage.type = 'error';
                        this.fadeAwayMessage.message = 'Something went wrong try again!';
                        this.fadeAwayMessage.header = 'System Message';
                        }
                    })
         }
      },
    getLIBCategories(){
      this.axiosCall("/get/LIBCategories", "GET").then((res) => {
        this.LIBCategories = res.data.data;
      });
    },
    print(data){
      window.open(this.apiUrl + "/noa/report/" + data.NOAID);
    },
    PO(data){
      if(this.$store.state.user.roleID == 5){
      window.open(this.apiUrl + "/po/report/" + data.POID + '/1');
      }
      else{
      window.open(this.apiUrl + "/po/report/" + data.POID + '/0');
      }
    },
    NTP(data){
      if(data.NTPID){
        window.open(this.apiUrl + "/ntp/report/" + data.NTPID);
      }
      else{
        this.selectedData = data
        this.NTPDate = null;
        this.ntpDialog = true;
      }
      
    },
    confirmNTP(){
      let data = new FormData();
        data.append('NOAID', this.selectedData.NOAID);
        data.append('POID', this.selectedData.POID);
        data.append('PRID', this.selectedData.PRID);
        data.append('Date', this.NTPDate || '');
        this.axiosCall("/noa/generateNTP", "POST", data).then((res) => {
          if(res.data.status){
            window.open(this.apiUrl + "/ntp/report/" + res.data.data);
            this.initialize();
            this.ntpDialog = false;
          }
          else{
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = 'error';
            this.fadeAwayMessage.message = 'Something went wrong.';
            this.fadeAwayMessage.header = 'System Message';
          }
        });
    }
    }
  }
</script>